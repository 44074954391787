import React, { FC, useEffect, useState } from 'react';
import { Link, navigate, PageProps } from 'gatsby';

import 'bootstrap/dist/css/bootstrap.css';
import '../../styles/Index.scss';
import classes from './career.module.scss';

import Navbar from '../../components/Navbar/Navbar';
import Hero, { HeroType } from '../../components/Hero/Hero';
import { CareerDetailCaption, OtherLinks, WP_URL } from '../../constants';
import Layout from '../../components/Layout/Layout';
import SEO from '../../components/SEO/SEO';
import { Row, Col, Spinner } from 'react-bootstrap';
import { CareerListItem } from '../../pages/careers';
import ContactForm, {
  ContactFormType,
} from '../../components/ContactForm/ContactForm';
import { Html5Entities } from 'html-entities';

const Career: FC<PageProps> = ({ path, params }) => {
  const [career, setCareer] = useState<CareerListItem>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    fetch(`${WP_URL}/wp-json/wp/v2/posts?slug=${params.career}`)
      .then((response) => response.json())
      .then((posts: CareerListItem[]) => {
        posts.length ? setCareer(posts[0]) : navigate('/careers');
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        navigate('/404');
      });
  }, [params.career]);

  return (
    <Layout>
      <SEO title={career?.title?.rendered} />
      <Hero page={HeroType.CAREERS}>
        <Navbar pathname={path} />
        <div className={classes.TitleContainer}>
          <div className={classes.Title}>
            {CareerDetailCaption.HEADER_TITLE}
          </div>
        </div>
      </Hero>
      <Row className={classes.FirstSection}>
        <Col sm={12} md={5} className={classes.FirstLeftColumn}>
          <p className={classes.FirstLeftColumnDescription}>
            {CareerDetailCaption.FIRST_SECTION_LEFT_COLUMN}
          </p>
          <div className={classes.FirstLeftColumnTitle}>
            {Html5Entities.decode(career?.title?.rendered)}
          </div>
        </Col>
        <Col sm={12} md={7} className={classes.FirstRightColumn}>
          {!loading ? (
            <>
              <div
                dangerouslySetInnerHTML={{ __html: career?.content?.rendered }}
              />
              <Link className={classes.ContentButton} to={OtherLinks.CAREERS}>
                {CareerDetailCaption.OTHER_POSITIONS}
              </Link>
            </>
          ) : (
            <div className={classes.SpinnerWrapper}>
              <Spinner
                as="span"
                animation="border"
                role="status"
                aria-hidden="true"
                className={classes.Spinner}
              />
            </div>
          )}
        </Col>
      </Row>
      <div className={classes.SecondSection}>
        <Hero page={HeroType.GREY_BACKGROUND} />
        <Row className={classes.GreyContainer}>
          <Col sm={12} md={5} className={classes.LeftGreyColumn}>
            <p className={classes.LeftGreyColumnTitle}>
              {CareerDetailCaption.SECOND_SECTION_TITLE}
            </p>
            <div className={classes.LeftGreyColumnDescription}>
              <p>{CareerDetailCaption.SECOND_SECTION_DESCRIPTION_P1}</p>
              <p>{CareerDetailCaption.SECOND_SECTION_DESCRIPTION_P2}</p>
            </div>
          </Col>
          <Col sm={12} md={7} className={classes.RightGreyColumn}>
            <ContactForm type={ContactFormType.CAREER} />
          </Col>
        </Row>
      </div>
    </Layout>
  );
};

export default Career;
